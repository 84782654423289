import util from "./lib/util.js";
import { getLegalInformationBySources } from "@red-digital/hughesnet-developer-kit";

cohesion("ready", () => {
  const legalInfo = getLegalInformationBySources(["site"]);
  console.log(legalInfo, "legalInfo");
  if (!window.sessionStorage.hnLegal) {
    const hnLegalString = legalInfo.legalStatements;
    window.sessionStorage.setItem("hnLegal", JSON.stringify(hnLegalString));
  }

  util.addAnnotationsToPage();
  util.addAnnotationsToFooter();
});
