const activeClass = "isActive";

/**
 * Adds activeClass to the provided html element.
 * @param element html element
 */
function addActiveClass(element) {
  const { classList } = element;
  classList.add(activeClass);
}

/**
 * Adds legal annotation numbers and text from sessionStorage to the footer.
 * (meant to be run after addAnnotationsToPage).
 */
function addAnnotationsToFooter() {
  try {
    const hnLegal = window.sessionStorage.hnLegal
      ? JSON.parse(window.sessionStorage.hnLegal)
      : [];
    console.log(hnLegal, "hnLegal, addAnnotationsToFooter");
    const annotationsToAddToFooter = window.sessionStorage
      .annotationsToAddToFooter
      ? JSON.parse(window.sessionStorage.annotationsToAddToFooter)
      : {};
    const legalTextElement = document.querySelector(".js-legal-text");

    if (!legalTextElement) {
      console.error(
        "No element with class .js-legal-text found in the document."
      );
      return;
    }
    legalTextElement.innerHTML = "";

    Object.keys(annotationsToAddToFooter).forEach((annotation) => {
      const number = annotationsToAddToFooter[annotation];
      const legalObj = Array.isArray(hnLegal.data)
        ? hnLegal.data.filter((obj) => obj && obj.handle === annotation)
        : [];
      if (legalObj.length === 0 || !legalObj[0].hasOwnProperty("statement")) {
        console.error(
          `No legal object found for annotation ${annotation} or the legal object doesn't have a legal property.`
        );
        return;
      }

      const { statement } = legalObj[0];
      const legalTextElementToInsert = `<p>[${number}] ${statement}</p>`;

      legalTextElement.insertAdjacentHTML(
        "beforeend",
        legalTextElementToInsert
      );
    });
  } catch (e) {
    console.error("There was a problem adding annotation to footer:", e);
  }
}

/**
 * Adds incrementing annotation numbers to their corresponding data-legal-annotation
 * placeholders, and creates an object on sessionStorage of annotation keys that need
 * to be added to the footer, along with their corresponding annotation number.
 * (meant to be run before addAnnotationsToFooter).
 */
function addAnnotationsToPage() {
  if (window.sessionStorage.hnLegal) {
    const annotationPlaceholders = document.querySelectorAll(
      "[data-legal-annotation]"
    );
    const annotationsToAddToFooter = {};
    let annotationCount = 1;

    Array.prototype.forEach.call(annotationPlaceholders, (annotation) => {
      const annotationValue = annotation.dataset.legalAnnotation;

      annotation.innerHTML = ""; // eslint-disable-line

      if (
        Object.keys(annotationsToAddToFooter).indexOf(annotationValue) === -1
      ) {
        annotationsToAddToFooter[annotationValue] = annotationCount;
        annotationCount += 1;
      }
      annotation.innerHTML = annotationsToAddToFooter[annotationValue]; // eslint-disable-line
    });

    window.sessionStorage.setItem(
      "annotationsToAddToFooter",
      JSON.stringify(annotationsToAddToFooter)
    );
  }
}

/**
 * Returns an array from an array-like element or collection of array-like elements.
 * @param element {collection} of array-like elements (node-list, html collection, etc.)
 *  or {object} single html element
 */
function arrayFrom(element) {
  return Array.prototype.slice.call(element);
}

/**
 * Adds the event listener function to the provided html element(s).
 * @param element {array} of html elements or {object} single html element
 * @param listener {function}
 */
function addListeners(element, listener) {
  const elements = element.length ? arrayFrom(element) : [element];

  elements.forEach((el) => {
    el.addEventListener("click", listener);
  });
}

/**
 * Adds innerHTML to provided html element(s)
 * @param {object} element - The node element, or node elements we want to add HTML to
 * @param {string} text - The string we want to copy to inner HTML
 */
function addInnerHTML(element, text) {
  const elements = element.length ? arrayFrom(element) : [element];

  elements.forEach((el) => {
    el.innerHTML = text;
  });
}

function addTagularTrackingEvents() {
  const ctaElements = document.getElementsByClassName("js-track-cta");
  const ctaArray = arrayFrom(ctaElements);

  ctaArray.forEach((cta) => {
    const { location, position, elementType, text, actionOutcome } =
      cta.dataset;

    cta.addEventListener("click", () => {
      tagular("beam", "ElementClicked", {
        // eslint-disable-line
        "@type": "redventures.usertracking.v3.ElementClicked",
        webElement: {
          location,
          position,
          elementType,
          text,
          htmlId: "",
        },
        actionOutcome,
      });
    });
  });
}

/**
 * Adds voice page legal annotation text
 */
function addVoiceAnnotationToFooter() {
  const legalTextStaticElement = document.querySelector(
    ".js-legal-text-static"
  );
  const voicePageAnnotation =
    "<p>Service is subject to Hughesnet Voice Subscription Agreement and Reasonable Use Policy. Service (including 911/emergency services) will not function during periods of Internet service outage. Additional Voice adapter equipment required. Applicable countries for International calling plans can be found on legal.Hughesnet.com. Transfers of an existing telephone number are not always available, and your new telephone number may not be a local number.</p>";

  legalTextStaticElement.insertAdjacentHTML("beforeend", voicePageAnnotation);
}

/**
 * Checks to see if user has a particular session storage property on their browser, presumably meaning they've submitted form previously
 * @param {object} windowObj - The window object, with the sessionStorage API
 */
function hasSessionInfo(windowObj) {
  return !!windowObj.sessionStorage.getItem("availablePlans");
}

/**
 * Checks for presence of "NY" in value of packageName property
 * Returns true if found, concluding address is in New York
 * Object shape reference is below!
 * {
 *   checkType: "residential",
 *   message: "serviceable",
 *   packages:
 *     20GB: {packageID: 171, packageName: "JU Lease Package | Broadband | NY", systemPackageName: "HNS Offer_01L_JU_NY", active: "y", creditCheckRequired: "n", …}
 *     100GB: {packageID: 171, packageName: "JU Lease Package | Broadband | NY", systemPackageName: "HNS Offer_01L_JU_NY", active: "y", creditCheckRequired: "n", …}
 *   serviceabilityBeam: "standard",
 *   systemPackageName: "HNS Offer_01L_JU_NY"
 * }
 * @param {objet} obj - The plan object
 * @returns {boolean} - True if we find string that suggests it's in NY
 */
function isInNewYork(obj) {
  try {
    if (obj.systemPackageName.includes("NY")) {
      return true;
    }
    return false;
  } catch (err) {
    console.error("Error attempting to parse object for New York address", err);
    return err;
  }
}

/**
 * Returns the appropriate header and subhead strings based on
 * the error message returned from the API.
 */
function processErrorMessage(error) {
  const heading =
    error === "unserviceable"
      ? "It looks like we can't service that address"
      : "Oops, we can't find what you're looking for";

  const subhead =
    error === "unserviceable"
      ? "Try checking that you have correctly entered your service address information."
      : "The page you're looking for doesn't exist or there was an unknown error.";

  return {
    heading,
    subhead,
  };
}

/**
 * Removes activeClass from the provided html elements.
 * @param element {array} of html elements or
 * list of html elements ex. (element1, element2, element3, ...etc)
 */
function removeActiveClass(element, ...rest) {
  const elements =
    element.length && element.type !== "select-one"
      ? arrayFrom(element)
      : [element, ...rest];

  elements.forEach((el) => {
    const { classList } = el;

    if (classList.contains(activeClass)) {
      classList.remove(activeClass);
    }
  });
}

/**
 * Removes the event listener from the provided html elements.
 * @param element {array} of html elements or {object} single html element
 * @param listener {function}
 */
function removeListeners(element, listener) {
  const elements = element.length ? arrayFrom(element) : [element];

  elements.forEach((el) => {
    el.removeEventListener("click", listener);
  });
}

/**
 * Toggles activeClass on event.currentTarget
 * @param {event} event
 */
function toggleActiveClass(event) {
  const { classList } = event.currentTarget;

  if (classList.contains(activeClass)) {
    removeActiveClass(event.currentTarget);
  } else {
    addActiveClass(event.currentTarget);
  }
}

/**
 * Sets the Cohesion anonymous id (or fallback) to the value attribute of selected element
 */
function setFormTrackingID() {
  const trackingField = document.getElementById("trackingId") || {};
  trackingField.value =
    window._Cohesion.sessionId || "ba8ee173-cc20-4e65-b9d0-0c52e4cda2d6"; // Fallback to default tenant ID
}

export default {
  activeClass,
  addActiveClass,
  addAnnotationsToFooter,
  addAnnotationsToPage,
  addListeners,
  addInnerHTML,
  addTagularTrackingEvents,
  addVoiceAnnotationToFooter,
  arrayFrom,
  hasSessionInfo,
  isInNewYork,
  processErrorMessage,
  removeActiveClass,
  removeListeners,
  toggleActiveClass,
  setFormTrackingID,
};
